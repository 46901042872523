import { useEffect, useState } from 'react';
import { getAudits, isTimeToReFetch } from '../services/data/auditsData';

export interface IAudit {
  date: string;
  name: string;
  desc: string;
  path: string;
  auditors: string;
  sourceCodeUrl: string;
  commit: string;
}

interface IAuditsStorage {
  lastfetchTimeStamp: number;
  audits: IAudit[];
}

export const useAudits = () => {
  const [audits, setAudits] = useState<IAudit[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const storedAuditData = localStorage.getItem('CS_Audits');

      if (storedAuditData !== null) {
        const parsedData: IAuditsStorage = JSON.parse(storedAuditData);
        if (isTimeToReFetch(parsedData.lastfetchTimeStamp)) {
          const audits = await getAudits();
          setAudits(audits);
        } else {
          setAudits(parsedData.audits);
        }
      } else {
        const audits = await getAudits();
        setAudits(audits);
      }
      setLoading(false);
    })();
  }, []);

  return { audits, loading };
};
