import React from 'react';
import './index.scss';

export const PrivacyPolicy: React.FC = () => (
  <article className="terms">
    <h1>Privacy Policy</h1>
    <p>Last modified: April 2, 2022</p>

    <p>
      ChainSafe Systems Inc. (<strong>“ChainSafe”</strong>, <strong>“we”</strong>, <strong>“us”</strong> and terms of
      similar meaning) respects your privacy and is committed to protecting it by complying with the terms outlined in
      this ChainSafe Privacy Policy (this<strong> “Privacy Policy”</strong>). This Privacy Policy describes how we
      collect, use, share and protect the personal information of our users (<strong>“you”</strong>) on the ChainSafe
      website (located at https://chainsafe.io) (the <strong>“Site”</strong>).
    </p>

    <p>
      We will only use your personal information in accordance with this Privacy Policy unless otherwise required by
      applicable law. We take steps to ensure that the personal information we collect about you is adequate, relevant,
      not excessive, and used for limited purposes. By accessing the Site, you confirm that you have read and understand
      our Terms of Use (the <strong>"Terms”</strong>) and this Privacy Policy before using the Site.
    </p>
    <h2>1. Personal Information We Collect</h2>
    <p>
      <strong>Directly.</strong> We collect and use your personal information to provide you with a secure and safe
      experience when accessing and using the Site. Our collection and use of your personal information will be limited
      to that which is necessary to provide you with a tailored experience. For that reason, you may be prompted to
      provide us with personal information through the course of using the Site, which may include your first name, last
      name, email, address or phone number.
    </p>
    <p>
      <strong>Directly.</strong> We collect and use your personal information to provide you with a secure and safe
      experience when accessing and using the Site. Our collection and use of your personal information will be limited
      to that which is necessary to provide you with a tailored experience. For that reason, you may be prompted to
      provide us with personal information through the course of using the Site, which may include your first name, last
      name, email, address or phone number.
    </p>
    <p>
      <strong>How Your Information is Collected.</strong> We collect personal information from you when you communicate
      with us through the Site. We may collect your personal information through third-party service providers that
      integrate with the Site.
    </p>

    <h2>2. Information We Collect Through Cookies and Other Automatic Data Collection Technologies</h2>
    <p>
      <strong>Indirectly.</strong> As you use the Site, we may use cookies or other automatic data collection
      technologies to collect personal information and information about your equipment, browsing actions, and patterns
      from the use of the Site. ChainSafe uses reputable automated technologies and third-party service providers to
      perform these functions. Any information derived through our use of cookies and other tracking technologies is
      used to provide us with analytics, Site, Application and other Site improvements. Information collected may
      include:
    </p>
    <ul>
      <li>
        details of your usage of the Site, including traffic data, location data, regional settings, logs, other
        communication data and the resources that you access and use; and
      </li>
      <li>
        information about your computer and internet connection, including your IP address, operating system, and
        browser type.
      </li>
    </ul>
    <p>
      <strong>Cookies.</strong> A “cookie” is a small file placed on the hard drive of your computer. You may refuse to
      accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting,
      you may be unable to access certain parts of our Site. Unless you have adjusted your browser setting to refuse
      cookies, our system will issue cookies when you direct your browser to our Site.
    </p>

    <h2>3. How We Use Your Information</h2>
    <p>
      <strong>Uses of Personal Information.</strong> We use the information that we collect about you or that you
      provide to us to provide the Site. We may use your information:
    </p>
    <ul>
      <li>to operate the Site and makes its contents available to you;</li>
      <li>to provide you with information, products or services that you request from us;</li>
      <li>
        to provide administrative notices and other communications relevant to your use of our Site under applicable
        laws;
      </li>
      <li>to provide you with customer support and assist with the provision of the Site;</li>
      <li>
        to fulfill the purposes for which you provided the information or that were described when it was collected or
        for any other purpose for which you provide it;
      </li>
      <li>to carry out our obligations and comply with applicable law; and</li>
      <li>for any other purpose with your consent and as otherwise outlined in our Terms.</li>
    </ul>

    <h2>4. Disclosure of Your Information</h2>
    <p>
      <strong>Disclosure of Personal Information. </strong>We may disclose the information that we collect about you or
      that you provide via Site, in accordance with this Privacy Policy. We may share your personal information with
      third-parties for the following reasons:
    </p>
    <ol>
      <li>
        <strong> Affiliated Companies:</strong> We may share your information with our subsidiaries, parent company and
        other affiliated entities to effectively provide you with the Site.
      </li>
      <li>
        <strong> Third-Party Service Providers:</strong> We may share your information with service providers,
        contractors, the organizations they act on behalf of, and other third-parties (such as cloud hosting, analytics
        and search engine providers) we use to support our business. These third-party service providers may require us
        to provide them with your information for these third-party service providers to perform or facilitate these
        functions. If we provide them with your information, we will contractually obligate them to keep your
        information confidential, use it only for the purposes for which we disclose it to them, and to process the
        personal information with the same standards set out in this policy.
      </li>
      <li>
        <strong> Legal Requirements:</strong> We may share your information to comply with any court order, law, or
        legal process, including to respond to any government or regulatory request under applicable law.
      </li>
      <li>
        <strong> Business Transactions:</strong> ChainSafe reserves the right to share your information in connection
        with any proposed or realized merger, divestiture, consolidation, restructuring, purchase, sale or any other
        type of business combination that requires its disclosure.
      </li>
    </ol>
    <h2>5. Security of Collected Information</h2>
    <p>
      <strong>Security Measures.</strong> ChainSafe uses physical, electronic, technical and administrative safeguards
      designed to secure your personal information from accidental loss and unauthorized access, use, modification, and
      disclosure. We store all of the information you provide to us on secure servers. Although we implement measures to
      protect your personal information, we cannot guarantee the security of your personal information transmitted to
      the Site. Any transmission of personal information to the Site is done at your own risk. We are not responsible
      for the circumvention of any privacy settings or security measures contained on the Site.
    </p>
    <h2>6. Data Retention</h2>
    <p>
      <strong>Retention Policy.</strong> We may periodically de-identify or delete personal information and unnecessary
      information that our administrators flag. We will delete unnecessary or unused personal information after two (2)
      years.
    </p>
    <h2>Children’s Privacy</h2>
    <p>
      <strong>Children's Privacy.</strong> The Site is not intended for children under thirteen (13) years of age. We do
      not knowingly collect personal information from children under thirteen (13) years of age. If you are under
      eighteen (18) years of age, we must receive your parent or legal guardian’s consent before collecting, using, or
      disclosing your information on the Site. If we learn we have collected or received personal information from a
      child without verification of parental consent, we will delete that information. If you (i) believe we might have
      any information from or about a child; (ii) would like to review any personal information we may have collected
      about your child; or (iii) would like to request the deletion of your child's personal information, please contact
      our Privacy Officer at the email address below.
    </p>
    <h2>Changes to our Privacy Policy</h2>
    <p>
      <strong>Changes to this Policy.</strong> We may make changes to our Privacy Policy from time to time. It is our
      policy to post any changes we make on this page. If we make any material changes to how we treat your personal
      information, we will notify you by emailing the email address specified in your account or posting a notice about
      the changes on a conspicuous area of the Site.
    </p>
    <p>
      We include the date the Privacy Policy was last revised at the top of the page. You are responsible for
      periodically visiting our Site and this Privacy Policy to check for any changes.
    </p>
    <h2>Contact</h2>
    <p>
      If you have any questions, comments, or requests regarding this Privacy Policy or our privacy practices, please
      contact us at:
      <a href="mailto:info@chainsafe.io"> info@chainsafe.io</a>
    </p>
  </article>
);
