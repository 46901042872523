import './index.scss';
import { Button } from '../../components/Button';
import { Link } from 'react-router-dom';

export const NotFound: React.FC = () => {
  return (
    <div className="not-found">
      <h1>Oops, we couldn't find the page you were looking for.</h1>
      <Link to="/">
        <Button dark>Return to Home</Button>
      </Link>
    </div>
  );
};
