import React from 'react';
import './index.scss';
import { Button } from '../../components/Button';
import { engineeringServices } from '../../services/data/servicesData';

export const Engineering: React.FC = () => (
  <article className="engineering">
    <div className="engineering--container">
      <div className="engineering--header">
        <h1 className="title">Software Engineering</h1>
      </div>
      <div className="first-section">
        <div className="double-column">
          <h4>Learn about ChainSafe's approach and principles to engineering.</h4>
        </div>
        <br></br>
        <div>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <Button primary>Read the Handbook</Button>
          </a>
          <a href="mailto:info@chainsafe.io">
            <Button dark>Get in touch</Button>
          </a>
        </div>
      </div>
      <div className="case-study">
        <div className="double-column">
          <h4>Node Implementations</h4>
          <p className="body-text">
            <strong>Client diversity for robust decentralized networks</strong>
            <br></br>
            <br></br>
            We pride ourselves on technological objectivity, we reject tribalism, and we're dedicated to building public
            goods. This means working with industry leaders on alternative client implementations for a variety of
            blockchains.{' '}
          </p>
        </div>
      </div>
      <div className="featured-service">
        <div className="double-column">
          <h4>Tools, APIs, Libraries</h4>
          <div>
            <p className="body-text">
              <strong>Tools that enable builders to do more</strong>
              <br></br>
              <br></br>
              We focus much of our energy on building tools, APIs, and libraries for others to use. We also spend a lot
              of time maintaining and troubleshooting—ensuring code is future-proof and works backward and forwards.
              Check out what we've already built, or submit a new request today.
            </p>
          </div>
        </div>
        <div className="items">
          {engineeringServices.map(({ name, to }, i) => (
            <a key={i} href={to} rel="noopener noreferrer" target="_blank">
              <div className="item">
                <p className="body-text">{name}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="service">
        <h4>Cryptography</h4>
        <div className="service--container">
          <p className="body-text">
            <strong>Expertise to help secure your protocol or application</strong>
            <br></br>
            <br></br>
            From Go-Schnorrkel to BLS to an AssemblyScript Implementation of SHA-256, we have cryptography experts that
            understand niche technology and can apply their knowledge to a wide range of use cases, scenarios, and
            design challenges.
          </p>
        </div>
      </div>
    </div>
  </article>
);
