export const upcomingEvents = [
  {
    title: 'Buidl Week',
    to: 'https://www.ethdenver.com/buidl/buidlweek',
    date: 'Feb 24-March 1',
    inPerson: true,
  },
  {
    title: 'ETHDenver',
    to: 'https://www.ethdenver.com',
    date: 'March 2-5',
    inPerson: true,
  },
  {
    title: 'Gaming Developer Conference (GDC)',
    to: 'https://gdconf.com/',
    date: 'March 20-24',
    inPerson: true,
  },
  {
    title: 'ETHGlobal Toronto',
    to: 'https://ethglobal.com/events/toronto',
    date: 'June 23-25',
    inPerson: true,
  },
  {
    title: 'ETHCC',
    to: 'https://ethcc.io/',
    date: 'July 17-20',
    inPerson: true,
  },
];

export const links = [
  {
    title: 'YouTube',
    to: 'https://www.youtube.com/c/chainsafesystems',
  },
  {
    title: 'Medium',
    to: 'https://blog.chainsafe.io/',
  },
  {
    title: 'Twitter',
    to: 'https://twitter.com/ChainSafeth',
  },
];
