import { useState, useEffect } from 'react';
import './index.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { IViewportSize, useViewportSize } from './use-viewport-size';
import { staggerChildren, staggerSelf, backgroundBounce } from './animation-variants';
import classNames from 'classnames';

export interface MenuItem {
  id?: number;
  name: string;
  linkUrl: string;
  description?: string;
}

const menu: MenuItem[] = [
  {
    id: 3,
    name: 'Products',
    linkUrl: '/products',
  },
  {
    id: 4,
    name: 'Services',
    linkUrl: '/services',
  },
  {
    id: 1,
    name: 'Public Goods',
    linkUrl: '/public-goods',
  },
  {
    id: 5,
    name: 'Company',
    linkUrl: '/company',
  },
  {
    id: 5,
    name: 'Community',
    linkUrl: '/community',
  },
];

export const Header: React.FC = () => {
  const [isOpen, toggleOpen] = useState(false);
  const [darkmode, setDarkmode] = useState(false);
  const [viewportSize, setViewportSize] = useState<IViewportSize>({
    width: undefined,
    height: undefined,
  });

  const { width, height } = useViewportSize();

  useEffect(() => {
    setViewportSize({ width, height });
  }, [width, height]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/products' || pathname === '/public-goods') {
      setDarkmode(true);
    } else {
      setDarkmode(false);
    }
  }, [pathname, setDarkmode]);

  return (
    <nav className={classNames(darkmode ? 'navbar__dark' : 'navbar')}>
      <div className="brand">
        <img
          src="https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/5646cd55-8803-4fec-bc22-95c20ad0dd00/public"
          alt="ChainSafe Logo"
        />
        <NavLink to="/" className={classNames(darkmode ? 'name__dark' : 'name')}>
          ChainSafe
        </NavLink>
      </div>
      {viewportSize.width && viewportSize.width > 930 && (
        <ul className="navbar--menu">
          {menu.map(({ name, linkUrl }) => (
            <NavLink to={linkUrl} className={classNames(darkmode ? 'menu-item__dark' : 'menu-item')} key={name}>
              {name}
            </NavLink>
          ))}
        </ul>
      )}
      {viewportSize.width && viewportSize.width < 930 && (
        <motion.nav initial={false} className="mobile-menu" animate={isOpen ? 'open' : 'closed'}>
          <motion.div className="mobile-menu--background" variants={backgroundBounce} />
          <motion.ul variants={staggerChildren} className="navMenu">
            {menu.map((i) => (
              <motion.li
                key={i.name}
                className={'navlink'}
                variants={staggerSelf}
                whileHover={{ x: -8 }}
                whileTap={{ scale: 0.93 }}
              >
                <a target="__blank" rel="noopener noreferrer" href={i.linkUrl} className="noUnderline">
                  {i.name}
                </a>
              </motion.li>
            ))}
          </motion.ul>
          <button className="button" onClick={() => toggleOpen(!isOpen)}>
            <svg width="26" height="26" viewBox="0 0 27 22" fill="none" strokeWidth={1.5}>
              <motion.path
                strokeWidth={1.5}
                stroke="#000"
                d="M 2 2.5 L 24 2.5"
                variants={{
                  closed: { d: 'M 2 2.5 L 24 2.5' },
                  open: { d: 'M 3 16.5 L 22 1' },
                }}
              />
              <motion.path
                strokeWidth={1.5}
                stroke="#000"
                d="M 2 9.423 L 24 9.423"
                variants={{
                  closed: { opacity: 1 },
                  open: { opacity: 0 },
                }}
                transition={{ duration: 0.1 }}
              />
              <motion.path
                strokeWidth={1.5}
                stroke="#000"
                d="M 2 16.346 L 24 16.346"
                variants={{
                  closed: { d: 'M 2 16.346 L 24 16.346' },
                  open: { d: 'M 3 1 L 22 16.346' },
                }}
              />
            </svg>
          </button>
        </motion.nav>
      )}
    </nav>
  );
};
