import React from 'react';
import './index.scss';
import { publicGoods } from '../../services/data/publicGoods';
import { Button } from '../../components/Button';

export const PublicGoods: React.FC = () => (
  <article className="public-goods">
    <div className="public-goods--container">
      <div className="header">
        <h1 className="title"> Supporting Public Goods</h1>
      </div>
      <div className="description">
        <h4>
          Our goal is to foster cryptoeconomic systems that make a positive impact. From alternative clients to
          libraries to plugins and developer tools, ChainSafe is building core infrastructure the web3 ecosystem needs
          to succeed.
        </h4>
      </div>
      <div className="public-goods--grid">
        {publicGoods.map(({ name, tags, description, imgUrl, to }, index) => (
          <a href={to} rel="noopener noreferrer" target="_blank">
            <div className="project" key={index}>
              <h2>{name}</h2>
              <div className="thumbnail-container">
                <img src={imgUrl} alt="" className="thumbnail" />
              </div>
              <p className="description">{description}</p>
              <p className="tags">
                {tags?.map((tag, index) => (
                  <span key={index} className="tag">
                    #{tag}{' '}
                  </span>
                ))}
              </p>
              <a className="visit" href={to}>
                <Button dark>Visit Website</Button>
              </a>
            </div>
          </a>
        ))}
      </div>
    </div>
  </article>
);
