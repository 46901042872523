import './index.scss';
import { IAudit, useAudits } from '../../hooks/useAudits';
import { getAuditImgUrl, getAuditPDF } from '../../utils';
import cls from 'classnames';
import { Button } from '../Button';
import { compareAuditDate } from '../../services/data/auditsData';

interface IAuditsGrid {
  singleAuditPagePath?: string;
}

export const AuditsGrid: React.FC<IAuditsGrid> = () => {
  const { audits } = useAudits();

  const AuditTile = (audit: IAudit): JSX.Element => {
    return (
      <div key={audit.name + audit.date} className="audit-tile">
        <img src={getAuditImgUrl(audit.name)} alt={audit.name} />
        <div>
          <p className="date">{audit.date}</p>
          <h3>{audit.name}</h3>
          <p className="audit-tile__short_desc">{audit.desc}</p>
          <div className="button-group">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={getAuditPDF(audit.name, audit.path)}
              className="download-link"
            >
              <Button dark>Download PDF</Button>
            </a>
            <a target="_blank" rel="noopener noreferrer" href={audit.sourceCodeUrl} className="download-link">
              <Button>Source</Button>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return <div className={cls('audits-grid')}>{audits.sort(compareAuditDate).map(AuditTile)}</div>;
};
