interface Option {
  title: string;
  img: string;
  desc: string;
  to: string;
}

export const options: Option[] = [
  {
    title: 'Products',
    desc: 'Browse and use our suite of game-changing products.',
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/60ccd663-a4ef-4323-780a-c12b81342000/public',
    to: '/products',
  },
  {
    title: 'Services',
    desc: 'View our full list of available services.',
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/88a7e3af-6b81-4709-9361-5839d9a42000/public',
    to: '/services',
  },
  {
    title: 'Public Goods',
    desc: 'Explore our foundational contributions to web3.',
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/02d06cad-ed49-43e5-b024-8654e8a98000/public',
    to: '/public-goods',
  },
];
