import React from 'react';
import { Router } from './pages/Router';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './utils/scrollToTop';

export const App: React.FC = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Router />
  </BrowserRouter>
);
