export const backgroundBounce = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 1200px 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(0px at 1200px 40px)',
    transition: {
      delay: 0.25,
      type: 'spring',
      stiffness: 300,
      restDelta: 5,
      damping: 40,
      display: 'none',
    },
  },
};

export const staggerChildren = {
  open: {
    transition: { staggerChildren: 0.14, delayChildren: 0.2 },
    display: 'block',
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
    display: 'none',
  },
};

export const staggerSelf = {
  open: {
    opacity: 1,
    transition: {
      y: { stiffness: 40, velocity: 50 },
    },
  },
  closed: {
    opacity: 0,
    transition: {
      y: { stiffness: 40, velocity: 50 },
      display: 'none',
    },
  },
};
