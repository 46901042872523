import React, { useState, useEffect, useCallback } from 'react';
import './index.scss';
import { options } from '../../services/data/home/options';
import { ClientTestimonial, testimonialData } from '../../services/data/home/testimonials';
import { clientsRow1, clientsRow2 } from '../../services/data/home/clientLogos';
import { backedLogos } from '../../services/data/home/backedBy';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';

const words = ['smart', 'right'];

export const Home: React.FC = () => {
  const [word, setWord] = useState('right');

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * words.length);
    setWord(words[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 2000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <>
      <article className="home">
        <div className="home--container">
          <section className="home--hero">
            <div className="title">
              <h1 className="title--h1">
                Building on blockchains the <span>{word}</span> way.
              </h1>
              <h2 className="title--h2">
                ChainSafe is a leading blockchain R&D firm, specializing in protocol engineering, cross-chain
                interoperability & web3 gaming.
              </h2>
              <a className="title--btn" href="mailto:info@chainsafe.io">
                <Button dark>Build with Us</Button>
              </a>
            </div>
          </section>
          <section className="home--options">
            {options.map(({ title, desc, img, to }, index) => (
              <div className="option" key={index}>
                <Link to={to}>
                  <img src={img} alt="" />
                </Link>
                <Link to={to}>{title}</Link>
                <p className="description">{desc}</p>
              </div>
            ))}
          </section>
          <section className="home-callout">
            <div className="column">
              <div className="column--content-wrapper">
                <h1>Powering the Decentralized Web</h1>
                <div className="text-wrapper">
                  <p>
                    Our mission is to enable the world's systems to run on decentralized infrastructure. We take a
                    multi-chain perspective with blockchain engineering experience that spans timezones, cultures, use
                    cases, and protocols.
                  </p>
                </div>
              </div>
            </div>
            <div className="column"></div>
          </section>
          <section className="home-clients">
            <h3>Clients & Partners</h3>
            <div className="home-clients--row1">
              {clientsRow1.map(({ to, img }, index) => (
                <a key={index} href={to} target="_blank" rel="noopener noreferrer">
                  <img src={img} alt={to} />
                </a>
              ))}
            </div>
            <div className="home-clients--row2">
              {clientsRow2.map(({ to, img }, index) => (
                <a key={index} href={to} target="_blank" rel="noopener noreferrer">
                  <img src={img} alt={to} />
                </a>
              ))}
            </div>
          </section>
          <section className="home-hire">
            <div className="home-hire--item">
              <h1>Partner with ChainSafe</h1>
              <h2>World-class engineering, at your service.</h2>
            </div>
            <div className="home-hire--item hire--services">
              <Link to="/services">
                <Button dark>View services</Button>
              </Link>
            </div>
          </section>
          <section className="home-reviews">
            <div className="left-column">
              <div className="left-column--content-wrapper">
                <h1 className="title">Words from Clients & Partners</h1>
              </div>
            </div>
            <div className="right-column">
              <div className="right-column--content-wrapper">
                {testimonialData.map(({ client, testimonial }: ClientTestimonial) => (
                  <div className="review">
                    <p className="review--text">{testimonial}</p>
                    <p className="review--caption">{client}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="home-careers">
            <div className="home-careers--column">
              <img
                src="https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/68390864-fbc8-459b-d523-715c6c585600/public"
                alt="The ChainSafe team at Devcon VI in Bogota"
              />
            </div>
            <div className="home-careers--column">
              <div className="home-careers--column--content-wrapper">
                <h1>Work at ChainSafe</h1>
                <p>Join friendly people building open-source technology for a better future.</p>
                <Link to="/careers">
                  <Button dark>View Openings</Button>
                </Link>
              </div>
            </div>
          </section>
          <section className="backed-by">
            <h3>Backed by</h3>
            <div className="backed-by--wrapper">
              {backedLogos.map(({ to, img, name }, index) => (
                <div key={index} className="backed-by--card">
                  <img src={img} alt={to} />
                  <p>{name}</p>
                </div>
              ))}
            </div>
          </section>
        </div>
      </article>
    </>
  );
};
