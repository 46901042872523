import React from 'react';
import './index.scss';

export const Terms: React.FC = () => (
  <article className="terms">
    <h1>TERMS OF SERVICE</h1>
    <p className="caption">Last Modified: April 2 2022</p>
    <p>
      These Terms of Service (these <strong>“Terms”</strong> or this <strong>“Agreement”</strong>) govern your access to
      and use of ChainSafe Systems (the <strong>Site</strong>, located at https://.chainsafe.io), made available by
      ChainSafe Systems Inc. (<strong>“we”</strong>, <strong>“us”</strong>, and <strong>“ChainSafe”</strong>.
    </p>
    <p>
      By accessing or using the Site, you acknowledge that you have read and understand these Terms. You agree to be
      bound by these Terms and all terms, policies and guidelines incorporated by reference herein. If you do not agree
      with these Terms, you may not access or use the Site.
    </p>

    <h2>1. CHANGES TO THESE TERMS</h2>
    <p>
      <strong>Changes to these Terms.</strong> ChainSafe reserves the right, in its sole discretion, to change, modify,
      replace, add or suspend portions of these Terms and our Privacy Policy at any time by posting amended terms on the
      Site. If you continue to access the Site after the amended Terms are posted, you will be deemed to have accepted
      such terms through your continued use of the Site. If at any point you do not agree with the Terms or Privacy
      Policy we may have at such time, your license to use the Site shall immediately terminate, and you must
      immediately stop using the Site.
    </p>
    <h2>2. PRIVACY POLICY</h2>
    <p>
      <strong>Privacy Policy. "Personal Information"</strong> means information that identifies, relates to, describes,
      is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a
      particular consumer or household. By accessing the Site and submitting your Personal Information, you consent to
      the collection, use, transmission and disclosure of your Personal Information in accordance with our privacy
      policy <strong>(“Privacy Policy”)</strong>.
    </p>
    <h2>3. ACCOUNTS</h2>
    <p>
      <strong>User Accounts.</strong> You may browse the Site without registering for an account or providing us with
      your Personal Information.
    </p>

    <h2>4. INTELLECTUAL PROPERTY OWNERSHIP AND LICENSE</h2>
    <p>
      <strong>4.1. Intellectual Property Ownership.</strong> You acknowledge and agree that the Site and its entire
      contents, features, and functionality, including, but not limited to, all information, software, code, text,
      displays, graphics, photographs, video, audio, design, presentation, selection, and arrangement, are owned by us,
      our licensors, or other providers of such material and are protected in all forms by intellectual property rights
      including, without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.
      Except for the rights and licenses granted in this Agreement, ChainSafe and its licensors (as applicable) shall
      retain all rights, title, interests, copyright, patent rights, trade secret rights, trademarks and other
      proprietary rights or interests <strong>(“Intellectual Property Rights”)</strong> in the Site. Nothing in this
      Agreement shall be deemed to transfer or convey ownership of any Intellectual Property Rights from one party to
      the other.
    </p>
    <p>
      Any use of the Site not expressly permitted by these terms and conditions is a breach of these Terms and may
      infringe or violate Intellectual Property Rights and other proprietary laws. The ChainSafe name, ChainSafe logo
      and all related names, logos, product and service names, designs, images, and slogans are trademarks of ChainSafe
      or its affiliates or licensors. You must not use such marks without our prior written consent.
    </p>
    <p>
      <strong>4.2. License to the Site. </strong>Subject to these Terms, ChainSafe hereby grants you a non-exclusive,
      non-transferable, revocable license to use the Site and the functionality contained therein.
    </p>
    <p>
      <strong>4.3. Restrictions.</strong> Except as outlined in these Terms and to the extent contrary to applicable
      law, the license to the Site granted in Section 4.2 is subject to the following restrictions. You may not:
    </p>
    <ol>
      <li>
        copy, distribute, modify, create derivative works or adapt the Site, except as permitted under these Terms;
      </li>
      <li>use the Site in a manner that violates any applicable law or breaches the rights of any third-party;</li>
      <li>
        reverse engineer, disassemble, decompile or decode the Site or otherwise attempt to derive or gain access to the
        source code of the Site, in whole or in part;
      </li>
      <li>
        rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the Site
        to any person, including on or in connection with the internet or any subscription service, time-sharing,
        software as a service, cloud or other technology or service;
      </li>
      <li>bypass or breach any security device, mechanism or protection used for or contained in the Site;</li>
      <li>replicate or recreate any part, design, function, feature or tool on the Site;</li>
      <li>use, or access the Site to build a competitive product or service or for any other competitive purposes;</li>
      <li>
        submit via the Site any data, software or other materials that contain any malicious code, viruses, worms,
        Trojan horses, defects, date bombs, time bombs or other items of a destructive nature;
      </li>
      <li>take any actions that impose an unreasonable burden on the Site’ technological infrastructure;</li>
      <li>submit inaccurate, false, or misleading information to the Site;</li>
      <li>
        distribute, publish, send or facilitate the sending of unsolicited mass email or other messages, promotions,
        advertising or solicitations, including commercial advertising and informational announcements;
      </li>
      <li>
        remove any legal, copyright, trademark or other proprietary rights notices contained in the Site or on materials
        you receive or access pursuant to this Agreement;
      </li>
      <li>
        attempt to gain unauthorized access to the Site, the servers on which the Site are stored, or any server,
        computer or database connected to the Site.
      </li>
    </ol>
    <h2>5. SITE FEEDBACK AND REVIEWS</h2>
    <p>
      <strong>User Feedback and Reviews.</strong> You grant ChainSafe a worldwide, perpetual, irrevocable, royalty-free
      license to use and incorporate into the Site any suggestions, reviews, enhancement requests, recommendations,
      corrections or other feedback relating to the Site that you provide to ChainSafe.
    </p>
    <h2>6. THIRD-PARTY SOFTWARE</h2>
    <p>
      <strong>Third-Party Software and Licenses.</strong> The Site contains third-party software that may be subject to
      their own licenses. These licenses may have additional terms and conditions. You understand and acknowledge that
      any third-party software accessed through the Site is not licensed to you pursuant to the provisions of these
      Terms and that these Terms may not be construed to grant any such right or license to you. The licenses that
      govern any third-party software are incorporated into these Terms. By accepting these Terms and accessing and
      using the Site, you also accept the third-party software licenses. To access any third-party licenses, please
      contact us at <a href="mailto:support@chainsafe.io">support@chainsafe.io</a>
    </p>

    <h2>7. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
    <div>
      <p>7.1</p>
      <div>
        <p>
          <strong>DISCLAIMER OF WARRANTIES.</strong> THE SITE, ITS CONTENTS, AND ANY FEATURES OR INFORMATION FOUND
          THROUGH THE SITE ARE PROVIDED ON AN “AS IS” BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER
          EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, OR NON-INFRINGEMENT. YOU ACKNOWLEDGE AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE AND
          ITS CONTENTS IS DONE SO AT YOUR OWN RISK. ANY RISKS RESULTING FROM THE ACCESS AND USE OF THE SITE REMAIN
          SOLELY WITH YOU.
        </p>
        <br />
        <p>
          NEITHER CHAINSAFE NOR ITS SUBSIDIARIES, AFFILIATES, OR THEIR RESPECTIVE AGENTS OR REPRESENTATIVES MAKE ANY
          WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY,
          ACCURACY, CURRENCY, OR AVAILABILITY OF THE SITE OR ITS CONTENTS. NEITHER CHAINSAFE NOR ITS SUBSIDIARIES,
          AFFILIATES, OR THEIR AGENTS REPRESENT NOR WARRANT THAT THE SITE, ITS CONTENTS, OR ANY FEATURES OR INFORMATION
          FOUND THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR THAT OUR TECHNOLOGICAL INFRASTRUCTURE THAT
          MAKES THE SITE AVAILABLE TO YOU IS FREE OF VIRUSES, MALICIOUS CODE OR OTHER TECHNOLOGICALLY HARMFUL
          COMPONENTS.
        </p>
      </div>
    </div>
    <br />
    <div>
      <p>7.2</p>
      <div>
        <p>
          <strong>Limitation of Liability. </strong> Except where such exclusions are prohibited by applicable law,
          under no circumstance will ChainSafe nor its subsidiaries, affiliates or their respective directors, officers,
          employees, agents, service providers, contractors, licensors, licensees, suppliers, or successors be liable
          for negligence, gross negligence, negligent misrepresentation, fundamental breach, damages of any kind, under
          any legal theory, including any direct, indirect, special, incidental, consequential, or punitive damages
          relating to your use of the Site. This includes any of the foregoing resulting from, but not being limited to,
          intellectual property infringement, violations of privacy rights, inaccuracy of the content provided by the
          Site, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of
          use, loss of goodwill, loss of data, and whether caused by tort, breach of contract, breach of privacy, or
          otherwise, even if you were allegedly advised or had reason to know. ChainSafe will also not be liable for any
          damages arising out of or in connection with your use, inability to use, or reliance on the Site, its content,
          or any linked websites or such other third party software, materials, postings, or information thereon even if
          you were allegedly advised or had reason to know.
        </p>
      </div>
    </div>
    <h2>8. SECURITY OF THE SITE</h2>
    <p>
      <strong>Security.</strong> ChainSafe implements appropriate administrative, physical and technical security
      measures designed to protect the security and confidentiality of Personal Information against any accidental or
      unauthorized destruction, alteration, access or disclosure to third-parties. Although measures are taken to
      protect your Personal Information, the security of any data or information transmitted via the Site cannot be
      guaranteed. Any transmission of information or data via the Site is done so at your own risk.
    </p>

    <h2>9. MODIFICATION OF SITE</h2>
    <p>
      <strong>Modifications to the Site.</strong> ChainSafe strives to continually improve the Site's offering. In
      improving the Site, we may change, modify, replace, add or remove features, content and functionalities. If we
      make changes to the Site that materially impact your use of the Site, we may post a notice on the site or email
      you at a provided address (if applicable).
    </p>

    <p>
      ChainSafe may (a) change, suspend or discontinue any aspect of the Site; or (b) terminate your access or licence
      to any or all parts of the Site at any time, with or without cause, with or without notice, effective immediately.
      ChainSafe may also impose limits on certain features and offerings or restrict your access to parts of the Site
      without notice or liability. Your continued use of the Site following notice of any changes to these Terms
      constitutes acceptance of those changes.
    </p>
    <h2>10. GOVERNING LAW</h2>
    <p>
      <strong>Governing Law.</strong> This Agreement shall be governed by and construed in accordance with the laws of
      the Province of Ontario and the Federal laws of Canada as applicable therein, without giving effect to any choice
      or conflict of law provision, principle, or rule (whether of the laws of the Province of Ontario or any other
      jurisdiction) and notwithstanding your domicile, residence, or physical location.
    </p>
    <h2>11. QUESTIONS</h2>
    <p>
      If you have any questions regarding these Terms or your use of the Site, please contact us here:{' '}
      <a href="mailto:support@chainsafe.io">support@chainsafe.io</a>
    </p>
  </article>
);
