import './index.scss';
import { useMatch } from 'react-router-dom';
import { getAuditPDF } from '../../utils';
import { Button } from '../../components/Button';
import { useAudit } from '../../hooks/useAudit';
import { AuditsGrid } from '../../components/AuditsGrid';

export const Audit: React.FC = () => {
  const path = useMatch('/audits/:path');
  const { singleAudit } = useAudit(path?.params.path);
  return (
    <div className="single-audit-page">
      <div className="single-audit">
        {singleAudit && (
          <>
            <div className="single-audit__wrapper">
              <p className="single-audit__wrapper__date">{singleAudit.date}</p>
              <div>
                <h1>{singleAudit.name}</h1>
                <p className="audit-tile__short_desc">{singleAudit.desc}</p>
                <div className="single-audit__button-wrapper">
                  <a target="_blank" rel="noreferrer noopener" href={getAuditPDF(singleAudit.name, singleAudit.path)}>
                    <Button>Download PDF</Button>
                  </a>
                </div>
              </div>
              <div className="single-audit__info">
                <div>Audited by</div>
                <div>{singleAudit.auditors}</div>
                <div>Commit hash</div>
                <div>{singleAudit.commit}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <AuditsGrid singleAuditPagePath={path?.params.path} />
    </div>
  );
};
