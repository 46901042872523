import {
  Baby,
  BellSlash,
  CalendarPlus,
  CurrencyCircleDollar,
  FlowArrow,
  GlobeHemisphereEast,
  TreeStructure,
  Heartbeat,
  Waves,
} from 'phosphor-react';

export interface BenefitsData {
  icon: JSX.Element;
  text: string;
}

export const benefitsData: BenefitsData[] = [
  { icon: <CurrencyCircleDollar size="1.5rem" />, text: 'Competitive salary' },
  { icon: <CalendarPlus size="1.5rem" />, text: 'Unlimited vacation' },
  { icon: <GlobeHemisphereEast size="1.5rem" />, text: 'Recognition as an industry leader' },
  { icon: <Baby size="1.5rem" />, text: 'Growth opportunities' },
  { icon: <FlowArrow size="1.5rem" />, text: 'Intentional and results-driven culture' },
  { icon: <TreeStructure size="1.5rem" />, text: 'Diverse projects' },
  { icon: <Waves size="1.5rem" />, text: 'Meaningful work' },
  { icon: <Heartbeat size="1.5rem" />, text: 'Attitude over aptitude' },
  { icon: <BellSlash size="1.5rem" />, text: 'Remote-first, flexible' },
];
