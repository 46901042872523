export interface FooterNavigation {
  to: string;
  title: string;
}

export const publicgoods: FooterNavigation[] = [
  { to: 'https://lodestar.chainsafe.io/', title: 'Lodestar' },
  { to: 'https://chainsafe.github.io/gossamer/', title: 'Gossamer' },
  { to: 'https://github.com/ChainSafe/forest', title: 'Forest' },
];

export const services: FooterNavigation[] = [
  { to: '/services/solutions', title: 'Solutions' },
  { to: '/services/engineering', title: 'Engineering' },
  { to: '/audits', title: 'Audits' },
];

export const products: FooterNavigation[] = [
  { to: 'https://gaming.chainsafe.io/', title: 'Gaming' },
  { to: 'https://files.chainsafe.io/', title: 'Files' },
  { to: 'https://storage.chainsafe.io/', title: 'Storage' },
];

export const company: FooterNavigation[] = [
  { to: '/about', title: 'About' },
  { to: '/careers', title: 'Careers' },
  { to: '/community', title: 'Community' },
  { to: '/events', title: 'Events' },
];

export const community: FooterNavigation[] = [
  { to: 'https://medium.com/chainsafe-systems', title: 'Blog' },
  { to: 'https://github.com/chainsafe', title: 'GitHub' },
  { to: 'https://discord.gg/xSAwrnCWcg', title: 'Discord' },
  { to: 'https://twitter.com/ChainSafeth', title: 'Twitter' },
  { to: 'https://www.linkedin.com/company/chainsafe-systems', title: 'LinkedIn' },
  { to: 'https://www.youtube.com/c/chainsafesystems', title: 'YouTube' },
];
