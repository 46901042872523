export interface ClientLogo {
  to?: string;
  img: string;
  name: string;
}

export const backedLogos: ClientLogo[] = [
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/36af9ec8-cb12-4560-e785-00b1eea67500/public',
    name: 'Round13',
  },
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/3b6f8858-0d90-4613-c237-baf863586900/public',
    name: 'NGC Ventures',
  },
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/0467a408-93cd-4bd7-bb33-acf4061c4b00/public',
    name: 'Consensys',
  },
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/120d1f3e-a893-4a79-a6a4-c428b41ae300/public',
    name: 'Hashkey Capital',
  },
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/910607a9-ad7c-48b7-7ef7-7c8ae6f51500/public',
    name: 'Consensys Mesh',
  },
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/32b2d51b-d401-4142-32dd-9a3ff6195a00/public',
    name: 'Sfermion',
  },
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/56a740e0-69b1-4672-18c3-c043bc118800/public',
    name: 'Fenbushi Capital',
  },
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/050ae8d3-c7e2-4174-4ba9-e82eda1d9700/public',
    name: 'DFG',
  },
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/173d2f60-2f23-4fc1-82f9-0613fd8b6800/public',
    name: 'Jsquare',
  },
  {
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/9bd96516-1057-4be0-ce12-035c16587f00/public',
    name: 'IOSG',
  },
];

export const backedRow1 = backedLogos.slice(0, 3);

export const backedRow2 = backedLogos.slice(3);
