import connext from '../../../assets/clients/connext.png';
import ef from '../../../assets/clients/ef.png';
import wealthsimple from '../../../assets/clients/wealthsimple.png';
import graph from '../../../assets/clients/graph.png';
import w3f from '../../../assets/clients/w3f.png';
import gtc from '../../../assets/clients/gtc.png';
import protocollabs from '../../../assets/clients/protocollabs.png';
import moonbeam from '../../../assets/clients/moonbeam.png';
import palm from '../../../assets/clients/palm-logo.png';
import push from '../../../assets/clients/push.jpeg';

export interface ClientLogo {
  to: string;
  img: string;
}

const clientLogos: ClientLogo[] = [
  {
    to: 'https://ethereum.foundation/',
    img: ef,
  },
  {
    to: 'https://thegraph.com/',
    img: graph,
  },
  {
    to: 'https://web3.foundation/',
    img: w3f,
  },
  {
    to: 'https://protocol.ai',
    img: protocollabs,
  },
  {
    to: 'https://gitcoin.co/',
    img: gtc,
  },
  {
    to: 'https://www.connext.network/',
    img: connext,
  },
  {
    to: 'https://www.wealthsimple.com/',
    img: wealthsimple,
  },
  {
    to: 'https://moonbeam.network/',
    img: moonbeam,
  },
  {
    to: 'https://palm.io/',
    img: palm,
  },
  {
    to: 'https://push.org/',
    img: push,
  },
];

export const clientsRow1 = clientLogos.slice(0, 4);

export const clientsRow2 = clientLogos.slice(4);
