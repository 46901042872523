import { Routes, Route, Outlet } from 'react-router-dom';
import { NotFound } from './NotFound/NotFound';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Audits } from './Audits';
import { Audit } from './Audit';
import { About } from './About';
import { Services } from './Services';
import { Solutions } from './Solutions';
import { Events } from './Events';
import { Engineering } from './Engineering';
import { Careers } from './Careers';
import { PublicGoods } from './PublicGoods';
import { Products } from './Products';
import { Community } from './Community';
import { Company } from './Company';
import { Home } from './Home';
import { Terms } from './Terms';
import { PrivacyPolicy } from './PrivacyPolicy';
// import { Experiment } from './Experiment/Experiment';

export const Router: React.FC = () => (
  <>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/audits" element={<Outlet />}>
        <Route index element={<Audits />} />
        <Route path=":auditName" element={<Audit />} />
      </Route>
      <Route path="/careers" element={<Careers />} />
      <Route path="/community" element={<Community />} />
      <Route path="/company" element={<Company />} />
      <Route path="/events" element={<Events />} />
      <Route path="/public-goods" element={<PublicGoods />} />
      <Route path="/products" element={<Products />} />
      <Route path="/services" element={<Outlet />}>
        <Route index element={<Services />} />
        <Route path="/services/engineering" element={<Engineering />} />
        <Route path="/services/solutions" element={<Solutions />} />
      </Route>
      <Route path="/terms" element={<Terms />} />
      {/* <Route path="/experiment" element={<Experiment />} /> */}
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
  </>
);
