import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';

const subpages = [
  {
    title: 'Solutions',
    to: '/services/solutions',
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/02d06cad-ed49-43e5-b024-8654e8a98000/public',
  },
  {
    title: 'Software Engineering',
    to: '/services/engineering',
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/60ccd663-a4ef-4323-780a-c12b81342000/public',
  },
  {
    title: 'Solidity Audits',
    to: '/audits',
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/88a7e3af-6b81-4709-9361-5839d9a42000/public',
  },
];

export const Services: React.FC = () => {
  return (
    <article className="services">
      <div className="services--header">
        <div className="headerTextWrapper">
          <h1 className="titleText">Services</h1>
          <h4>ChainSafe provides best-in-class engineering, consulting, and auditing services for web3.</h4>
        </div>
        <div className="service-container">
          {subpages.map(({ title, to, img }) => (
            <Link to={to}>
              <div key={title} className="service">
                <img src={img} alt="" />
                <h1>{title}</h1>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </article>
  );
};
