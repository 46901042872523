import React from 'react';
import './index.scss';
import { Timeline } from '../../components/Timeline';
import { Carousel } from '../../components/Carousel';
import { careersCarouselData } from '../../services/data/careers/careersCarouselData';

export const About: React.FC = () => {
  return (
    <div className="about">
      <section className="about--container">
        {/* header */}
        <div className="header">
          <h1 className="header--title">About</h1>
        </div>
        <div className="intro">
          <div className="intro--container">
            <p>
              ChainSafe is one of the few multi-chain research and development firms. We support the decentralized web
              through high-impact contributions to leading protocols. Our work comprises node implementations, smart
              contract engineering, distributed systems research, blockchain applications, tools, audits, and much more.
            </p>
            <p>
              Everything we work on is open-source and community-oriented. And our work in various ecosystems reflects
              the conviction that the full vision for web3 cannot be realized without persistence, collaboration, and an
              open mind.
            </p>
          </div>
          <img
            src="https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/cde14329-335b-4eba-7d96-cbe2edb52b00/public"
            alt=""
          />
        </div>
        {/* timeline */}
        <Timeline />
        {/* values */}
        <div className="about-values">
          <Carousel data={careersCarouselData} rotationMs={30000} />
        </div>
      </section>
    </div>
  );
};
