import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import './index.scss';
import { Container } from '../../components/Container';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export interface Job {
  id: number;
  internal_job_id: number;
  title: string;
  updated_at: string;
  requisition_id: string;
  location: {
    name: string;
  };
  absolute_url: string;
  metadata: null;
  content: string;
  departments: {
    id: number;
    name: string;
    parent_id?: null;
    child_ids?: number[] | null;
  }[];
  offices: {
    id: number;
    name: string;
    location: string;
    parent_id: number | null;
    child_ids: number[];
  }[];
}

interface JobList {
  jobs: Job[];
  meta: {
    total: number;
  };
}

// eslint-disable-next-line no-empty-pattern
export const Positions = forwardRef<HTMLDivElement>(({}, ref) => {
  const [openPositionsList, setOpenPositionsList] = useState<Job[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState(-1);

  useMemo(() => {
    if (!openPositionsList.length || selectedDepartment === -1) return [];
    const department = departments[selectedDepartment];
    return openPositionsList.filter((job) => job.departments.some(({ name }) => name === department));
  }, [openPositionsList, selectedDepartment, departments]);

  useEffect(() => {
    const greenhouseJobsUrl = 'https://boards-api.greenhouse.io/v1/boards/chainsafesystems/jobs?content=true';
    const protocolJobsUrl = 'https://chainsafe.github.io/protocol/assets/json/jobs.json';
    axios.get<JobList>(greenhouseJobsUrl).then((greenhouseResult) => {
      axios.get<JobList>(protocolJobsUrl).then((protocolResult) => {
        const allJobs = protocolResult.data.jobs.concat(greenhouseResult.data.jobs);
        setOpenPositionsList(allJobs);
        // create departments list
        const departmentsList = [...new Set(allJobs.map((job) => job.departments[0].name || '').filter(Boolean))];
        setDepartments(departmentsList);
        if (departmentsList.length) setSelectedDepartment(0);
      });
    });
  }, []);

  const headingRef = useRef<HTMLHeadingElement>(null);

  return (
    <Container id="openings" className="positions" ref={ref}>
      <h3 ref={headingRef}>Open Positions</h3>
      {/* <ScrollList
        width={headingRef}
        list={departments}
        onClick={setSelectedDepartment}
        selectedIndex={selectedDepartment}
      /> */}
      {openPositionsList.length ? (
        <TransitionGroup component="ul" className="positions--list-container">
          {openPositionsList.map((listing) => (
            <CSSTransition key={listing.id} timeout={500} classNames="positions--animation">
              <li>
                <a key={listing.id} target="__blank" rel="noopener" href={listing.absolute_url}>
                  <p>{listing.title}</p>
                </a>
              </li>
            </CSSTransition>
          ))}
        </TransitionGroup>
      ) : (
        <div>There are no open positions. </div>
      )}
    </Container>
  );
});
