import { FooterNavigation } from '../../services/data/navigation';
import { NavLink } from 'react-router-dom';
import './index.scss';
import { useNavigateToTop } from '../../utils/useNavigateToTop';

interface Props {
  title: string;
  external?: boolean;
  links: FooterNavigation[];
  titleUrl: string;
}

export const NavList: React.FC<Props> = ({ title, external, links, titleUrl }) => {
  const navigateToTop = useNavigateToTop();

  const navigateAndReset = (event: React.MouseEvent) => {
    event.preventDefault();
    navigateToTop(titleUrl);
  };

  return (
    <figure className="footer-column">
      <NavLink className="footer-column--header" to={titleUrl} onClick={navigateAndReset}>
        {title}
      </NavLink>
      <ul className="leaders">
        {links.map(({ title, to }, index) => (
          <li key={index}>
            {external ? (
              <a href={to} target="_blank" rel="noopener noreferrer">
                {title}
              </a>
            ) : (
              <NavLink to={to}>{title}</NavLink>
            )}
          </li>
        ))}
      </ul>
    </figure>
  );
};
