import { NavList } from './NavList';
import { company, products, publicgoods, services } from '../../services/data/navigation';
import { NavLink } from 'react-router-dom';
import { DiscordLogo, GithubLogo, YoutubeLogo, LinkedinLogo, TwitterLogo, MediumLogo } from 'phosphor-react';
import './index.scss';
import { Button } from '../Button';

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer--upper">
        <div className="footer--upper--left">
          <NavList titleUrl="/products" title="Products" links={products} external />
          <NavList titleUrl="/services" title="Services" links={services} />
          <NavList titleUrl="/public-goods" title="Public Goods" links={publicgoods} external />
          <NavList titleUrl="/company" title="Company" links={company} />
        </div>
        <div className="footer--upper--right">
          <a className="footer-link" href="mailto:info@chainsafe.io">
            <Button primary>Contact ChainSafe</Button>
          </a>
          <a className="footer-link" href="https://share.hsforms.com/1c-BWbnPuT4GRQi6lI2_xqw4kcdm">
            <Button dark>Subscribe for Updates</Button>
          </a>
          <div className="icons">
            <a href="https://discord.gg/xSAwrnCWcg" rel="noopener noreferrer" target="_blank">
              <DiscordLogo size={32} />
            </a>
            <a href="https://www.youtube.com/c/chainsafesystems" rel="noopener noreferrer" target="_blank">
              <YoutubeLogo size={32} />
            </a>
            <a href="https://github.com/chainsafe" rel="noopener noreferrer" target="_blank">
              <GithubLogo size={32} />
            </a>
            <a href="https://twitter.com/ChainSafeth" rel="noopener noreferrer" target="_blank">
              <TwitterLogo size={32} />
            </a>
            <a href="https://www.linkedin.com/company/chainsafe-systems" rel="noopener noreferrer" target="_blank">
              <LinkedinLogo size={32} />
            </a>
            <a href="https://medium.com/chainsafe-systems" rel="noopener noreferrer" target="_blank">
              <MediumLogo size={32} />
            </a>
          </div>
        </div>
      </div>
      <div className="rule"></div>
      <div className="footer--lower">
        <div className="bottom-left">
          <p className="bottom-link">{currentYear} &copy; ChainSafe Systems.</p>
          <a href="/brand-assets.zip" className="bottom-link">
            Brand Assets
          </a>
          <a href="/" className="bottom-link">
            Press Kit
          </a>
        </div>
        <div className="bottom-right">
          <NavLink className="bottom-link" to="/terms">
            Terms
          </NavLink>
          <NavLink className="bottom-link" to="/privacy-policy">
            Privacy
          </NavLink>
        </div>
      </div>
    </footer>
  );
};
