interface EngineeringService {
  name: string;
  to: string;
  desc?: string;
}

export const engineeringServices: EngineeringService[] = [
  {
    name: 'PINT',
    to: 'https://github.com/ChainSafe/PINT',
    desc: 'A Polkadot ecosystem index for investors.',
  },
  {
    name: 'Chainlink Cosmos',
    to: 'https://github.com/ChainSafe/chainlink-cosmos',
    desc: 'Cosmos SDK module for Chainlink data feeds',
  },
  {
    name: 'js-libp2p-gossipsub',
    to: 'https://github.com/ChainSafe/js-libp2p-gossipsub',
    desc: 'JavaScript implementation of Gossipsub',
  },
  {
    name: 'thegarii',
    to: 'https://github.com/ChainSafe/thegarii',
    desc: 'The polling service for Arweawve blocks',
  },
  {
    name: 'Firehose-Arweave',
    to: 'https://github.com/ChainSafe/firehose-arweave',
    desc: 'Firehose integration for Arweave',
  },
];
