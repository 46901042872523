import React, { useState } from 'react';
import './index.scss';
import cls from 'classnames';

interface TabProps {
  year: string;
  isActive: boolean;
  id: string;
  onClick?: any;
}

export const timelineData = [
  {
    year: '0',
    text: 'Founders meet at Ethereum Developers Meetup Toronto',
  },
  {
    year: '0',
    text: 'ChainSafe joins the Alpha Start-up Initiative at Web Summit in Lisbon',
  },
  {
    year: '0',
    text: 'Curriculum created for Ethereum Developer course at York University',
  },
  {
    year: '0',
    text: 'Started consulting, auditing, and node implementations for external clients',
  },
  {
    year: '1',
    text: 'ChainSafe initiated the Goerli testnet for Ethereum, becoming the predominant testing ground for all developers building on Ethereum',
  },
  {
    year: '1',
    text: 'Consulting relationship with Polymath and Shyft Network established',
  },
  {
    year: '1',
    text: 'ChainSafe initiated the Goerli testnet for Ethereum, becoming the predominant testing ground for all developers building on Ethereum',
  },
  {
    year: '2',
    text: 'Development begins on Gossamer, a Go implementation of the Polkadot Runtime Environment',
  },
  {
    year: '2',
    text: 'ChainSafe starts hosting Ethereum Developers Meetup Toronto',
  },
  {
    year: '2',
    text: 'Development begins on Forest, a Rust implementation of the Filecoin Protocol',
  },
  {
    year: '3',
    text: 'ChainSafe closes pre-seed round led by ConsenSys',
  },
  {
    year: '3',
    text: 'ChainSafe takes over development and maintenance of web3.js',
  },
  {
    year: '3',
    text: 'ChainSafe hosts first annual conference: CSCON[0]',
  },
  {
    year: '3',
    text: 'ChainSafe began developing ChainBridge, a modular multi-chain interoperability layer',
  },
  {
    year: '3',
    text: 'Closed Seed Round-A funding from lead investor Consensys Labs, with Fenbushi Capital and ETC Labs',
  },
  {
    year: '4',
    text: 'ChainSafe acquires blockchain R&D firm NodeFactory',
  },
  {
    year: '4',
    text: 'ChainSafe opens a satelite office in Berlin',
  },
  {
    year: '4',
    text: 'The ChainSafe team grows to 100+ members',
  },
  {
    year: '4',
    text: 'ChainSafe releases web3.unity, an open-source gaming SDK for web3, providing game engines with blockchain access',
  },
  {
    year: '4',
    text: 'ChainSafe receives two Chainlink Community Grants',
  },
  {
    year: '5',
    text: 'The ChainSafe Gaming SDK, web3.unity, extends support to Cronos',
  },
  {
    year: '5',
    text: 'ChainSafe releases FilSnap on MetaMask Flask to enable Filecoin support within MetaMask',
  },
  {
    year: '5',
    text: 'The ChainSafe Gaming SDK, web3.unity, adds two new features, NFT minting and an NFT marketplace.',
  },
  {
    year: '5',
    text: 'ChainSafe receives a grant from the Web3 Foundation to maintain and develop SubstrateSnap',
  },
  {
    year: '5',
    text: 'The Lodestar team was heavily involved in The Merge',
  },
  {
    year: '6',
    text: 'ChainSafe releases Lodestar v1.4.0',
  },
  {
    year: '6',
    text: 'ChainSafe develops Multix',
  },
  {
    year: '6',
    text: 'ChainSafe develops the prototype Zipline Casper',
  },
  {
    year: '6',
    text: 'ChainSafe is selected as a relaying partner for Sygma',
  },
  {
    year: '6',
    text: 'Forest becomes a fully operational consensus node',
  },
  {
    year: '6',
    text: 'ChainSafe Gaming develops Sanctuary, a custom NFT marketplace for Parallel',
  },
];

export const tabYears = [
  {
    id: '0',
    year: '2017',
  },
  {
    id: '1',
    year: '2018',
  },
  {
    id: '2',
    year: '2019',
  },
  {
    id: '3',
    year: '2020',
  },
  {
    id: '4',
    year: '2021',
  },
  {
    id: '5',
    year: '2022',
  },
  {
    id: '6',
    year: '2023',
  },
];

export const Tab: React.FC<TabProps> = ({ year, isActive, id, onClick }) => {
  return (
    <p key={id} id={id} onClick={onClick} className={cls({ 'tab--selected': isActive === true })}>
      {year}
    </p>
  );
};

export const Timeline: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleClick = (e: React.MouseEvent) => {
    let clickedTab = e.target as HTMLParagraphElement;
    const index = parseInt(clickedTab.id, 0);
    if (index !== activeTab) {
      setActiveTab(index);
    }
  };

  return (
    <div className="timeline">
      <div className="timeline--tabmenu">
        {tabYears.map(({ id, year }) => (
          <Tab key={id} isActive={activeTab === parseInt(id)} id={id} onClick={handleClick} year={year} />
        ))}
      </div>
      <div className="timeline-content">
        {timelineData.map(({ year, text }, index) => (
          <div key={index} className={cls('card', { card__visible: activeTab === parseInt(year) })}>
            <section className="line"></section>
            <div className="dot"></div>
            <p className="card-text">{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
