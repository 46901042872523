import axios from 'axios';
import { IAudit } from '../../hooks/useAudits';

export const gitHubAPI = axios.create({
  baseURL: `https://api.github.com/repos/chainsafe/audits`,
  timeout: 1000,
});

export const isTimeToReFetch = (lastFetch: number): boolean => {
  const timeNow = Date.now();
  const minutesToAllowReFetch = 5;
  return lastFetch + minutesToAllowReFetch * 60 * 1000 < timeNow;
};

export const getAudits = async (): Promise<IAudit[]> => {
  const auditsResponse = await gitHubAPI.get('contents/index.json?ref=main');

  const auditsJSON = atob(auditsResponse.data.content);
  const parsedAudits = JSON.parse(auditsJSON) as IAudit[];
  const lastFetch = Date.now();
  const CSAuditsData = {
    lastfetchTimeStamp: lastFetch,
    audits: parsedAudits,
  };
  localStorage.setItem('CS_Audits', JSON.stringify(CSAuditsData));
  return parsedAudits;
};

export function compareAuditDate(a: IAudit, b: IAudit): number {
  const [aMonth, aYear] = a.date.split('/');
  const [bMonth, bYear] = b.date.split('/');
  return (
    new Date(parseInt(bYear), parseInt(bMonth) - 1).getTime() -
    new Date(parseInt(aYear), parseInt(aMonth) - 1).getTime()
  );
}
