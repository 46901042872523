export interface ClientTestimonial {
  client: string;
  testimonial: string;
}

export const testimonialData: ClientTestimonial[] = [
  {
    client: 'Web3 Foundation',
    testimonial:
      'ChainSafe is a reliable implementer that steadily tries to improve their own performance and is constantly on the lookout for new ways to drive the growth of the web3 ecosystem. As a valuable contributor to the Kusama and Polkadot ecosystem, ChainSafe continues to produce a wide variety of infrastructure for both its clients and the wider community.',
  },
  {
    client: 'Palm NFT Studio',
    testimonial:
      "We’ve seen excellent results working with ChainSafe on a combination of projects including smart contract audits, solutions research, and engineering. Our team continues to be amazed by the quality of engineering talent assigned to each and every one of their engagements. Perhaps most important is ChainSafe's ability to deal with ambiguity and adapt to changes in scope and/or direction whenever necessary. We look forward to working with ChainSafe for many years to come.",
  },
  {
    client: 'Push (prev EPNS)',
    testimonial:
      'ChainSafe has worked very closely with us and completed an audit of the EPNS Protocol (Native Web3 Communication Protocol), $PUSH token, Staking and Time Vesting smart contracts. This was a major accomplishment for us late last year in October. Truly helped us mitigate all risks and in fact, helped us find new opportunities to implement! ChainSafe team acted more as an external team to understand the contract and features which resulted in a superior protocol and were extremely professional, supportive and helpful during the audit!',
  },
];
