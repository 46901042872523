import React from 'react';
import './index.scss';
import { Button } from '../../components/Button';

export const Solutions: React.FC = () => (
  <article className="solutions">
    <div className="solutions--container">
      <div className="solutions--header">
        <h1 className="title">Solutions</h1>
      </div>
      <h4>Read research and watch past talks on the Hub.</h4>
      <a href="https://solutions.chainsafe.io/">
        <Button dark>Visit Solutions Hub</Button>
      </a>
      <br />
      <div className="featured-service">
        <div className="double-column">
          <h4>Technical design advisory & implementation consulting</h4>
          <div>
            <p className="body-text">Insight to help you make better decisions</p>
            <p className="body-text">
              With our expertise, we help existing blockchain projects and new entrants to the space come up with better
              solutions. We’ve got you covered for strategy assessment, implementation specifications, cryptography,
              bridge integrations, and much more.
            </p>
          </div>
        </div>
      </div>
      <div className="service">
        <h4>Crypto-economics consulting</h4>
        <div className="service--container">
          <p className="body-text">Incentive systems that last</p>
          <p className="body-text">
            In crypto, solving technical challenges is often closely correlated with the economic (incentive) design of
            a system. This requires a particular breed of expertise—and our multi-disciplinary team has the deep,
            first-hand experience to engineer a design that works.
          </p>
        </div>
      </div>
      <div className="service">
        <h4>Prototyping</h4>
        <div className="service--container">
          <p className="body-text">A proving ground for novel ideas </p>
          <p className="body-text">
            We offer prototyping services to ensure that all elements of a design are working as expected and the
            viability of the proposed solution is established. Have assumptions you want to stress test? Get in touch
            with us.
          </p>
        </div>
      </div>
    </div>
  </article>
);
