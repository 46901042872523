import React from 'react';
import './index.scss';
import { AuditsGrid } from '../../components/AuditsGrid';

export const Audits: React.FC = () => (
  <article className="audits">
    <div className="audits--container">
      <div className="audits--header">
        <h1 className="title">Solidity Audits</h1>
      </div>
      <div className="audits--description">
        <h4>Comprehensive security assessments for your smart contract</h4>
        <h4>
          We've developed a reputation for smart contract auditing, and some of the biggest names in web3 now trust us
          with their code. We combine the best blockchain security analysis tools with a hands-on review to ensure that
          your application is production-ready.
        </h4>
        <a className="audits-button" href="mailto:info@chainsafe.io">
          Request a quote
        </a>
      </div>
      <div className="featured-audit">
        <h4>Audits by ChainSafe</h4>
        <AuditsGrid />
      </div>
    </div>
  </article>
);
