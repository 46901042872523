import React from 'react';
import './index.scss';
import { links, upcomingEvents } from './eventsData';
import { ArrowUpRight } from 'phosphor-react';
import { Button } from '../../components/Button';

export const Events: React.FC = () => (
  <div className="events">
    <article className="events--container">
      <div className="events-header">
        <h1 className="title">Meet ChainSafe at an event</h1>
        <a
          href="https://docs.google.com/spreadsheets/d/1jcTKPV2c4Vg2oigKU1WOsbWA0kemwJnifWWttGz6KFc"
          target="__blank"
          rel="noopener noreferrer"
        >
          <Button>
            View all events
            <ArrowUpRight width={18} />
          </Button>
        </a>
      </div>
      <div className="event-grid">
        <p>Upcoming conferences and events</p>
        {upcomingEvents.map(({ title, to, date, inPerson }, index) => (
          <div className="event" key={index}>
            <a href={to} target="__blank" rel="noreferrer">
              <p>
                {title}
                <ArrowUpRight width={18} />
              </p>
            </a>
            <p className="date">
              {date}
              <span>{inPerson ? 'In-Person' : 'Virtual'}</span>
            </p>
          </div>
        ))}
      </div>
      <div className="double-column">
        <div className="left-column">
          <p>Don't Miss an Update</p>
          <div className="links">
            {links.map(({ title, to }, index) => (
              <a href={to} target="__blank" rel="noopener noreferrer" key={index}>
                {title}
                <ArrowUpRight width={18} />
              </a>
            ))}
          </div>
        </div>
        <div className="right-column">
          <p>Recordings of Past Events</p>
          <div className="links">
            <a
              href="https://www.youtube.com/playlist?list=PLPn3rQCo3XrMkZnW0UZkyH9cJVK1iV3Og"
              rel="noopener noreferrer"
            >
              CSCON[1]
              <img className="external-link" src="./arrow.png" alt="" />
            </a>
            <a
              href="https://www.youtube.com/playlist?list=PLPn3rQCo3XrMkZnW0UZkyH9cJVK1iV3Og"
              rel="noopener noreferrer"
            >
              CSCON[0]
              <img className="external-link" src="./arrow.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </article>
  </div>
);
