import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import './index.scss';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const subMenu = [
  { to: '#work-mission', title: 'Mission' },
  { to: '#work-about', title: 'About' },
];

export const CareersNavigation: React.FC = () => {
  const [search] = useSearchParams();
  const createNavLinkProps = (to: string, sideMenu?: boolean) => ({
    to: { hash: to, search: search.toString() },
    replace: true,
    onClick: () => {
      document.querySelector(to)?.scrollIntoView({
        behavior: 'smooth',
      });
    },
    className: (): string => {
      if (location.hash.includes(to) && location.hash.includes('work') && to !== '#work')
        return 'scroll--sub-menu__active';
      if (location.hash.includes(to)) return 'scroll__active';
      return sideMenu ? 'scroll--sub-menu' : '';
    },
  });

  const location = useLocation();

  return (
    <div className="scroll">
      <nav>
        <ul>
          <li>
            <NavLink {...createNavLinkProps('#work')}>Why work with us</NavLink>
            <TransitionGroup component="ul">
              {(location.hash.includes('work') || location.hash.includes('stories') ? subMenu : []).map(
                ({ to, title }) => (
                  <CSSTransition key={to} timeout={300} classNames="positions--animation">
                    <li>
                      <NavLink {...createNavLinkProps(to, true)}>{title}</NavLink>
                    </li>
                  </CSSTransition>
                ),
              )}
            </TransitionGroup>
          </li>
          <li>
            <NavLink {...createNavLinkProps('#values')}>Our values</NavLink>
          </li>
          <li>
            <NavLink {...createNavLinkProps('#benefits')}>Benefits</NavLink>
          </li>
          <li>
            <NavLink {...createNavLinkProps('#openings')}>Open positions</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};
