import React from 'react';
import './index.scss';
import { Button } from '../../components/Button';
import { ArrowUpRight } from 'phosphor-react';

export const Community: React.FC = () => (
  <article className="community">
    <div className="community--grid">
      <section className="item">
        <h1>Read the latest updates on all projects and products.</h1>
        <a href="https://blog.chainsafe.io/" target="_blank" rel="noopener noreferrer">
          <Button primary>Visit Blog</Button>
        </a>
      </section>
      <section className="social-grid">
        <div className="links">
          <a href="https://discord.gg/xSAwrnCWcg" target="_blank" rel="noopener noreferrer" className="social-item">
            Join Discord
            <ArrowUpRight width={18} />
          </a>
          <a href="https://github.com/chainsafe" target="_blank" rel="noopener noreferrer" className="social-item">
            Visit GitHub
            <ArrowUpRight width={18} />
          </a>
          <a href="https://twitter.com/ChainSafeth" target="_blank" rel="noopener noreferrer" className="social-item">
            Visit Twitter
            <ArrowUpRight width={18} />
          </a>
        </div>
        <div className="featured-content">
          <a
            href="https://blog.chainsafe.io/announcing-the-chainsafe-ambassador-program-chainsages-41328eaaf203"
            target="_blank"
            rel="noopener noreferrer"
            className="featured-content--item"
          >
            <Button primary>Become a ChainSage</Button>
          </a>
        </div>
      </section>
    </div>
  </article>
);
