import { useCallback, useEffect, useState } from 'react';
import cls from 'classnames';
import './index.scss';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

export interface CarouselData {
  subTitle: string;
  title: string;
  description: string;
}

interface Props {
  data: CarouselData[];
  rotationMs?: number;
}

const getGridArea = (index: number, length: number): string => {
  const row = length / 2 > index ? 1 : 2;
  const position = length / 2 > index ? index + 1 : index - length / 2 + 2;
  return `${row} / ${position} / ${row + 1} / ${position + 1}`;
};

export const Carousel: React.FC<Props> = ({ data, rotationMs = 15000 }) => {
  const [position, setPosition] = useState(0);
  const handlePositionClick = (index: number) => () => {
    restartInterval();
    setPosition(index);
  };

  const handleInterval = useCallback(() => {
    setPosition((current) => {
      const nextPosition = current + 1;
      if (nextPosition === data.length) return 0;
      return nextPosition;
    });
  }, [data]);

  const [intervalId, setIntervalId] = useState<number>();

  const restartInterval = useCallback(() => {
    if (intervalId) clearInterval(intervalId);
    const interval = setInterval(handleInterval, rotationMs);
    setIntervalId(interval as unknown as number);
  }, [intervalId, rotationMs, handleInterval]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId, restartInterval]);

  return (
    <div className="carousel">
      <div className="dot-container">
        {data.map((_, index) => (
          <div
            key={index}
            onClick={handlePositionClick(index)}
            className={cls('dot', { 'dot--selected': position === index })}
            style={{ gridArea: getGridArea(index, data.length) }}
          />
        ))}
      </div>
      {data[position] && (
        <SwitchTransition mode="out-in">
          <CSSTransition key={position} timeout={500} classNames="carousel--animation">
            <div className="carousel--data">
              <h3>{data[position].subTitle}</h3>
              <h2>
                {9 > position && '0'}
                {position + 1}/ {data[position].title}
              </h2>
              <p>{data[position].description}</p>
            </div>
          </CSSTransition>
        </SwitchTransition>
      )}
    </div>
  );
};
