import { useEffect, useState } from 'react';
import { IAudit, useAudits } from './useAudits';

export const useAudit = (path?: string) => {
  const { audits, loading } = useAudits();
  const [singleAudit, setSingleAudit] = useState<IAudit>();

  useEffect(() => {
    (async () => {
      const singleAudit = audits.find((audit) => audit.path === path);
      setSingleAudit(singleAudit);
    })();
  }, [path, audits]);

  return { singleAudit, loading };
};
