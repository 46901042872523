import { CarouselData } from '../../../components/Carousel';

export const careersCarouselData: CarouselData[] = [
  {
    title: 'Openness',
    subTitle: 'Our values',
    description:
      'We welcome differing ideas and beliefs without judgment and practice candor and respect in all communications.',
  },
  {
    title: 'Learning',
    subTitle: 'Our values',
    description:
      'We relentlessly identify opportunities to improve both professionally and personally, while working on unproven but promising technology.',
  },
  {
    title: 'Collaboration',
    subTitle: 'Our values',
    description:
      'We believe that we can achieve more as a team than we ever could individually and are eager to give and receive constructive feedback in order to improve.',
  },
  {
    title: 'Compassion',
    subTitle: 'Our values',
    description:
      "We recognize each individual's humanity, treat everyone with love & kindness, and go above & beyond in helping everyone feel at home.",
  },
  {
    title: 'Accountability',
    subTitle: 'Our values',
    description:
      'We follow through on duties, responsibilities, & commitments in a timely fashion and admit mistakes even if no one else sees them while taking ownership for any consequences of our decisions.',
  },
  {
    title: 'Diligence',
    subTitle: 'Our values',
    description:
      'We hold ourselves and our colleagues accountable to a high standard and look for the long-term solution instead of providing a short-term fix.',
  },
  {
    title: 'Friendliness',
    subTitle: 'Our values',
    description:
      "We inject our personality and random fun things into ordinary routines and don't take things too seriously even in stressful situations.",
  },
  {
    title: 'Freedom',
    subTitle: 'Our values',
    description:
      'We respect autonomy and independence and encourage others to express themselves freely. We choose a schedule and location that fits our lifestyles.',
  },
];
