import { useCallback, useEffect, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CareersNavigation } from './CareersNavigation';
import { Carousel } from '../../components/Carousel';
import { benefitsData } from '../../services/data/careers/benefitsData';
import { Positions } from './Positions';
import { careersCarouselData } from '../../services/data/careers/careersCarouselData';
import { ArrowDown } from 'phosphor-react';
import './index.scss';
import { Button } from '../../components/Button';
import cls from 'classnames';

const observerOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
};

export const Careers: React.FC = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  // observe user location and update url accordingly
  const observations = useRef(new Map<string, number>());
  const observer = useRef(
    new IntersectionObserver((entries, obs) => {
      entries.forEach((entry) => {
        observations.current.set(entry.target.id, entry.intersectionRatio);
      });
      let winner = '';
      observations.current.forEach((value, key) => {
        if (!winner) winner = key;
        if (value > (observations.current.get(winner) || 0)) winner = key;
      });
      navigate({ hash: winner !== 'hero' ? winner : undefined, search: search.toString() }, { replace: true });
    }, observerOptions),
  );
  const observerObserveHelper = useCallback((node) => {
    if (node !== null) observer.current.observe(node);
  }, []);

  const scrollToCareers = () => {
    document.querySelector('#openings')?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.current.disconnect();
    };
  }, []);

  return (
    <div className="careers">
      {/* Section background's */}
      <div className="values-background" />
      <div className="benefits-background" />

      {/* HERO */}
      <div id="hero" className="hero" ref={observerObserveHelper}>
        <div className="hero--content">
          <h2>Build software that shapes the future with friendly and supportive human beings.</h2>
          <Link to={{ hash: '#openings', search: search.toString() }} onClick={scrollToCareers} replace>
            <Button primary>View current openings</Button>
          </Link>
        </div>
        <ArrowDown size="3rem" onClick={scrollToCareers} />
      </div>

      {/* Side scroll visible on big screen */}
      <CareersNavigation />

      {/* Why work with us */}
      <article id="work" className="work">
        <div id="work-mission" className="why-work" ref={observerObserveHelper}>
          <div>
            <h4>Mission</h4>
            <h3>Building a brazen future.</h3>
            <p>
              ChainSafe contributes to the decentralized web via multi-chain infrastructure projects, a unique product
              suite, and one-of-kind consulting services—all in support of our ultimate goal: to accelerate the
              evolution of web3.
            </p>
          </div>
          <img
            src="https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/c06de6bd-2524-435c-97bf-0d27e8aded00/public"
            className={cls('bigger')}
            alt="Jon Roethke and Hubert Bugaj representing ChainSafe at DevConnect"
          />
        </div>
        <div id="work-about" className="why-work" ref={observerObserveHelper}>
          <div>
            <h4>About us</h4>
            <h3>Diverse. Global. Open.</h3>
            <p>
              We’re committed to the ideals of decentralization, which means hiring the best people, regardless of
              background or geography. We have offices in Toronto, Berlin, and Zagreb, with team members around the
              globe.
            </p>
          </div>
          <img
            src="https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/6e8bf161-7e62-4a17-41f5-b739ee866700/public"
            alt="work-about-img"
          />
        </div>
      </article>

      {/* Our values */}
      <div id="values" className="values" ref={observerObserveHelper}>
        <Carousel data={careersCarouselData} rotationMs={30000} />
      </div>

      {/* Benefits */}
      <div id="benefits" className="benefits-info" ref={observerObserveHelper}>
        <div>
          <h4>Benefits</h4>
          <h3>Life at ChainSafe</h3>
        </div>
        <p>
          ChainSafe fosters a supportive, fun, and educational environment where work is about what you want to learn,
          not just what you already know.
        </p>
      </div>
      <div id="benefits" className="benefits" ref={observerObserveHelper}>
        <h3>How we support each ChainSafer's personal wellbeing:</h3>
        {benefitsData.map(({ icon, text }, index) => (
          <div key={index} className="benefit">
            <div>{icon}</div> <span>{text}</span>
          </div>
        ))}
      </div>

      {/* Open positions */}
      <Positions ref={observerObserveHelper} />

      <div id="cta" className="cta" ref={observerObserveHelper}>
        <h3>Have career-related questions? Reach out by email.</h3>
        <a href="mailto:talent@chainsafe.io">
          <Button primary>Contact the Talent Team</Button>
        </a>
      </div>
    </div>
  );
};
