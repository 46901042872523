import React from 'react';
import './index.scss';
import { products } from '../../services/data/products';
import { Button } from '../../components/Button';

export const Products: React.FC = () => (
  <article className="products">
    <div className="products--container">
      <div className="products--header">
        <h1 className="title">Unlock your Potential with ChainSafe Products</h1>
      </div>
      <div className="description">
        <h4>We're building solutions to accelerate the adoption of web3 infrastructure.</h4>
      </div>
      <div className="products--grid">
        {products.map(({ title, desc, img, to }) => (
          <a href={to} rel="noopener noreferrer" target="_blank">
            <div className="product">
              <img src={img} alt="" />
              <h2>{title}</h2>
              <p className="description">{desc}</p>
              <a href={to} rel="noopener noreferrer" target="_blank" className="visit">
                <Button dark>Visit Page</Button>
              </a>
            </div>
          </a>
        ))}
      </div>
    </div>
  </article>
);
