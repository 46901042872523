import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';

export const Company: React.FC = () => (
  <article className="company">
    <div className="company--grid">
      <section className="item about-item">
        <h1>ChainSafe's Story</h1>
        <Link to={'/about'}>
          <Button primary>Read about the company</Button>
        </Link>
      </section>
      <div className="double-column">
        <section className="item work-item">
          <h1>Work at ChainSafe</h1>
          <Link to={'/careers'}>
            <Button primary>Find open positions</Button>
          </Link>
        </section>
        <section className="events-item item">
          <h1>Meet other multi-chain enthusiasts</h1>
          <Link to={'/events'}>
            <Button primary>Find upcoming events</Button>
          </Link>
        </section>
      </div>
    </div>
  </article>
);
