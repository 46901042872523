interface Product {
  title: string;
  img: string;
  desc: string;
  to: string;
}

export const products: Product[] = [
  {
    title: 'Gaming',
    desc: 'Generate an in-game marketplace, use the NFT minter and much more. Our end-to-end gaming solution makes it easy to build web3 games. Build with us and elevate your game.',
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/0ffca7b4-fe2a-4a3d-008a-2fa439dd5100/public',
    to: 'https://gaming.chainsafe.io/',
  },
  {
    title: 'Storage',
    desc: 'We’ve released the part of our stack that interacts with the Filecoin network as a modular product, meaning you can add decentralized storage in just a few clicks.',
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/a2949dfb-7219-4714-8d36-e84bd3404100/public',
    to: 'https://storage.chainsafe.io/',
  },
  {
    title: 'Files',
    desc: 'ChainSafe Files provides peace of mind with decentralized, end-to-end encryption. Uploaded content is encrypted in transit, at rest, and when stored directly with Filecoin network miners.',
    img: 'https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/a844b8bd-bc69-4819-bce4-7062ded0b200/public',
    to: 'https://files.chainsafe.io/',
  },
];
