import styles from './index.module.scss';
import cls from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';

export const Container = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...rest }, ref) => (
    <div className={cls(styles.container, className)} ref={ref} {...rest}>
      {children}
    </div>
  ),
);
